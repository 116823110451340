import React, { useRef, useState } from 'react';
import { Box, Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import BackgroundImagePC from '../../BackgroundImg/BackgroundImgPC';
import Navbar from '../../navbar/Navbar';
import BackgroundImageMobile from '../../BackgroundImg/BackgroundImgMobile';
import { brandColor, orangeColor, whiteColor } from '../../Config/Color';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import axios from 'axios';
import { cekDataMember } from '../../Config/Api';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import TabletDeviceDetailMember from '../detailmember/TabletDeviceDetailMember';



const TabletDevice = () => {
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery('(min-width:600px)');

    const [captchaValue, setCaptchaValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [newData, setNewData] = useState({
        police_number: ''
    });
    const [responseData, setResponseData] = useState([]);
    const [switchPage, setSwitchPage] = useState(false);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(newData.police_number);
        setLoading(true)

        if (!captchaValue) {
            ErrorAlert({
                message: 'Please complete the CAPTCHA.',
                position: 'top-center'
            });

            setLoading(false)
            return;
        } else {
            // alert("CAPTCHA Matched");
            await axios.post(cekDataMember, {
                police_number: newData.police_number
            }).then((response) => {
                // console.log(response.data);
                if (response.data[0].message === 'data not found') {
                    setTimeout(() => {
                        ErrorAlert({
                            message: 'Data member tidak ditemukan',
                            position: 'top-center'
                        });
                        setLoading(false)
                    }, 2000);
                    // setCaptchaValue(null)
                } else {
                    setResponseData(response.data)
                    SuccessAlert({
                        message: 'Data member ditemukan.',
                        position: 'top-center'
                    });
                    setTimeout(() => {
                        setSwitchPage(true)
                        setLoading(false)
                    }, 3000);
                }
            }).catch((error) => {
                ErrorAlert({
                    message: error.response.data,
                    position: 'top-center'
                });
                setLoading(false)
                console.log(error.response.data);
            })
        }
    };


    const handleChange = (e, props) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });
    };


    return (
        switchPage ?
            <TabletDeviceDetailMember
                responseData={responseData}
                switchPage={() => setSwitchPage(false)}
                data={() => setNewData({
                    police_number: ''
                })}
            />
            :
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden',
                    backgroundColor: '#1111'
                }}
            >
                <Box sx={{ flex: '1.9', height: '100%', position: 'relative', }}>
                    <BackgroundImageMobile />
                </Box>
                <Box
                    sx={{
                        flex: '5.5',
                        // backgroundColor:'orange',
                        padding: '20px'
                    }}
                >
                    <Box
                        sx={{
                            // backgroundColor:'orange',
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            gap: '20px',
                            mt: '30px',
                            mb: '40px'
                        }}
                    >
                        <Navbar />
                    </Box>
                    <Box
                        sx={{
                            // backgroundColor: 'orange',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '70%',
                                // mt: "24px",

                                // backgroundColor: '#eee'
                            }}
                        >
                            <Box sx={{ marginTop: '24px', mb: "24px", }}>
                                <Typography sx={{ textAlign: 'center' }} >Masukkan nomor plat atau kode member untuk melakukan perpanjangan member.</Typography>
                            </Box>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="No Plat / Kode Member"
                                    placeholder='DD1234PW'
                                    value={newData.police_number}
                                    name="police_number"
                                    onChange={(e) => handleChange(e)}
                                    autoComplete="off"
                                    required
                                    // disabled
                                    sx={{
                                        gridColumn: "span 4",
                                        fontSize: "13px",
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "white",
                                        },
                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                            "& fieldset": {
                                                borderBottomColor: brandColor,
                                            },
                                        },
                                        "& .MuiInputLabel-root.Mui-disabled": {
                                            color: brandColor,
                                        },
                                        "& .MuiFilledInput-underline:before": {
                                            borderBottomColor: brandColor,
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: '#B3B3D4',
                                            fontWeight: 'bold'
                                        },
                                        "& .MuiInputLabel-root.Mui-focused": {
                                            color: brandColor,
                                        },
                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                            color: brandColor,
                                        },
                                        "& .MuiFilledInput-underline:hover:before": {
                                            borderBottomColor: brandColor,
                                        },
                                        "& .MuiFilledInput-underline:hover:after": {
                                            borderBottomColor: brandColor,
                                        },
                                        "& .MuiFilledInput-underline:focus": {
                                            borderBottomColor: brandColor,
                                        },
                                        "& .MuiFilledInput-underline:focus:before": {
                                            borderBottomColor: brandColor,
                                        },
                                        "& .MuiFilledInput-underline:focus:after": {
                                            borderBottomColor: brandColor,
                                        },
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                        {
                                            "-webkit-appearance": "none",
                                            margin: 0,
                                        },
                                        "& input[type=number]": {
                                            "-moz-appearance": "textfield",
                                        },
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mt: '20px',
                                        mb: '25px'
                                    }}
                                >
                                    <ReCAPTCHA
                                        sitekey="6LcZorUoAAAAAAuOGt6yR9PP1KOjfBp6LPngsrui"
                                        onChange={handleCaptchaChange}
                                        size='normal' // Adjust size as needed
                                        theme='light'
                                    />
                                </Box>
                                <Button
                                    fullWidth
                                    type="submit"
                                    size="medium"
                                    variant='contained'
                                    sx={{
                                        padding: '10px 0px',
                                        backgroundColor: brandColor,
                                        textTransform: 'none',
                                        width: '100%',
                                        // maxWidth: '100px',
                                        '&:hover': {
                                            color: 'white', // Change text color to white on hover
                                            backgroundColor: brandColor,
                                        },
                                    }}
                                    disabled={loading ? true : false}
                                >
                                    {loading ?
                                        <Icon icon='svg-spinners:bars-scale-fade' style={{ fontSize: '20px' }} />
                                        :
                                        <Typography
                                            sx={{
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            Cek Data Member
                                        </Typography>
                                    }
                                </Button>
                            </form>
                            <Box mt="24px">
                                <Typography sx={{ textAlign: 'center' }} >Pendaftaran member saat ini hanya dapat dilakukan di Lokasi yang dikelola oleh BSS.</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <ToastContainer />
            </Box>
    );
};

export default TabletDevice;
