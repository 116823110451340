import { Typography } from "@mui/material";
import moment from "moment";

const Timelimit = ({ selectedLocationData, selectedPackage, fontSize, fontWeight, color }) => {
    let extendMemberTimelimit = ''
    let currentDate = new Date()
    let currentTimelimit = new Date(selectedLocationData.time_limit)

    if (selectedLocationData.aktif === 'Ya') {
        // extendMemberTimelimit = currentTimelimit.setDate(currentTimelimit.getDate() + selectedPackage.duration)
        extendMemberTimelimit = new Date(selectedLocationData.time_limit);
        extendMemberTimelimit.setDate(
            extendMemberTimelimit.getDate() +
            selectedPackage.duration,
        );
    } else {
        extendMemberTimelimit = new Date(currentDate);
        extendMemberTimelimit.setDate(
            extendMemberTimelimit.getDate() +
            selectedPackage.duration - 1 ,
        );
        // extendMemberTimelimit = currentDate.setDate(currentDate.getDate() + selectedPackage.duration)
    }

    currentTimelimit.setDate(currentTimelimit.getDate() + 1)
    // console.log(moment(currentTimelimit).format('Do MMMM YYYY'));
    // console.log(selectedLocationData.aktif);
    // console.log(moment(extendMemberTimelimit).format('Do MMMM YYYY'));

    // var currentDate = new Date();
    // currentDate.setDate(
    //     currentDate.getDate() +
    //     dataPaket.props.datapaket.durasi -
    //     1,
    // );
    // var memberExpiredDate = moment(
    //     currentDate,
    //     'YYYY-MM-DD',
    // ).format('YYYY-MM-DD');
    return (
        <>
            <Typography sx={{ fontSize: fontSize, fontWeight: fontWeight, color: color }}>{extendMemberTimelimit ? moment(extendMemberTimelimit).format('Do MMMM YYYY') : undefined}</Typography>
        </>
    )
}

export default Timelimit