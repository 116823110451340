import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import bg from "../../assets/background/Rectangle1.png"
import bgMobile from "../../assets/background/bg-mobile.png"
import Logo from "../../assets/icon/Logo.png"
import styles from "./TextAnimation.css";
import { useMediaQuery } from '@material-ui/core';

const BackgroundImagePC = () => {
    const isNonMobile = useMediaQuery('(min-width:600px)');

    const [displayText, setDisplayText] = useState("Parkir");

    useEffect(() => {
        const textArray = ["Parkir", "Perumahan", "Apartemen"];
        let currentIndex = 0;

        const interval = setInterval(() => {
            currentIndex = (currentIndex + 1) % textArray.length;
            setDisplayText(textArray[currentIndex]);
        }, 5000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: 3,
                    marginTop: '50px',
                    marginLeft: '112px',
                    // backgroundColor:'green'
                }}
            >
                <img
                    src={Logo}
                    alt="parkways-logo"
                    style={{
                        width: '151.61px',
                        height: '32px',
                    }}
                />
                <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    marginTop='200px'
                    color='#ffffff'
                // alignItems='center'
                // backgroundColor='orange'
                >
                    <Typography
                        sx={{
                            fontSize: '32px',
                            fontWeight: '500',
                            lineHeight: '40.73px',
                            // fontFamily:'Inter'
                        }}
                    >
                        Parkways Membership</Typography>
                    <Typography
                        className={styles.textAnimation}
                        sx={{
                            fontSize: '56px',
                            fontWeight: '600',
                            lineHeight: '70.77px',
                            transition: 'ease-in-out 1s', // Add CSS transition   
                            // fontFamily:'Inter'
                        }}
                    >
                        Akses {displayText}</Typography>
                    <Typography
                        sx={{
                            fontSize: '32px',
                            fontWeight: '500',
                            lineHeight: '40.73px',
                            // fontFamily:'Inter'
                        }}
                    >
                        Lebih mudah, murah dan anti ribet.</Typography>
                </Box>
            </Box>
            <img
                src={bg}
                alt="parkways-background"
                width="100%"
                height="100%"
                style={{ position: 'absolute', zIndex: 1 }}
            />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="auto"
                height="768"
                viewBox="0 0 704 768"
                fill="none"
                style={{ position: 'absolute', zIndex: 2, height: '100%', }}
            >
                <path
                    d="M-5.5 623.5L196 422L379 239L555 63L649.5 -31.5M814 322L607 115M649.5 509.5L431 291M466.5 692.5L248 474M260.5 889.5L46.5 675.5"
                    stroke="#1B18A8"
                    strokeOpacity="0.3"
                    strokeWidth="13"
                    strokeLinecap="round"
                />
            </svg>
        </div>

    );
};

export default BackgroundImagePC;
