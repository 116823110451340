import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import BackgroundImagePC from '../../components/BackgroundImg/BackgroundImgPC';
import Navbar from '../../components/navbar/Navbar';
import BackgroundImageMobile from '../../components/BackgroundImg/BackgroundImgMobile';

const Lokasi = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  return (
    <>
      {isNonMobile ?
        <Grid
          container
          // rowSpacing={2}
          // columnSpacing={2.75}
          sx={{
            height: '100%',
            overflow: 'hidden'
          }}
        >
          <Grid item xs={5.5} sx={{ height: '100%' }}>
            <BackgroundImagePC />
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                marginTop: "50px",
                display: 'flex',
                flexDirection: 'column',
                // gap: '10px',
                width: '100%',
                height: '100%'
                // backgroundColor:'orange'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                  alignItems: 'end',
                  // backgroundColor: 'green',
                  gap: "20px"
                }}
              >
                <Navbar />
              </Box>
              <Box
                sx={{
                  flex: '3',
                  // backgroundColor: 'blue'
                }}
              >
                content lokasi
              </Box>
            </Box>
          </Grid>
        </Grid>
        :
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              overflow: 'hidden',
              backgroundColor: '#1111'
            }}
          >
            <Box sx={{ flex: '1.9', height: '100%', position: 'relative', }}>
              <BackgroundImageMobile />
            </Box>
            <Box
              sx={{ 
                flex: '5.5',
                // backgroundColor:'orange',
                padding:'20px'
              }}
            >
              <Box>
                <Navbar />
              </Box>
              <Box sx={{marginTop:'20px'}}>
                <Typography>content lokasi</Typography>
              </Box>
            </Box>
          </Box>

        </>
      }
    </>
  );
};

export default Lokasi;
