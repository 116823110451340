const ConvertIDR = (number) => {
    // Menghapus pemisah ribuan (titik)
    const cleanNumber = String(number).replace(/\./g, "");
    
    // Mengubah nilai menjadi format ribuan (3 digit)
    const formatNumber = new Intl.NumberFormat("id-ID").format(cleanNumber);
    
    return formatNumber;
  }

  export default ConvertIDR