import { Icon } from '@iconify/react';
import { Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Logo from "../../assets/icon/Logo.png"
import { brandColor } from '../Config/Color';

const Loading = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Mengatur waktu tunda sebagai contoh (dapat diganti dengan logika asinkron lainnya)
        const delay = 3000; // 3 detik
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, delay);

        // Membersihkan timer jika komponen dibongkar sebelum waktu tunda selesai
        return () => clearTimeout(timer);
    }, []);

    const handleSuccessLoading = () => {
        return (
            <>
                <Icon icon='line-md:confirm' style={{ fontSize: '80px' }} />
            </>
        )
    }

    return (
        <div>
            {isLoading ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection:'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // backgroundColor:'orange',
                        height: '100vh',
                        gap:'5px'
                    }}
                >
                    <img
                        src={Logo}
                        alt="parkways-logo"
                        style={{
                            width: '150px',
                            height: '30px',
                        }}
                    />
                    {/* <Typography sx={{fontSize:'10px', fontWeight:"bold"}} >Lebih muda, murah dan anti ribet.</Typography> */}
                    <Icon icon='svg-spinners:bars-scale-fade' style={{ fontSize: '30px', color:brandColor }} />
                </Box>
            ) : (
                <Box>{handleSuccessLoading()}</Box>
            )}
        </div>
    );
};

export default Loading;
