import React, { useRef, useState } from 'react';
import { Box, Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import BackgroundImagePC from '../../BackgroundImg/BackgroundImgPC';
import Navbar from '../../navbar/Navbar';
import BackgroundImageMobile from '../../BackgroundImg/BackgroundImgMobile';
import { brandColor, orangeColor, whiteColor } from '../../Config/Color';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import axios from 'axios';
import { cekDataMember } from '../../Config/Api';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import MobileDeviceDetailMember from '../detailmember/MobileDeviceDetailMember';



const MobileDevice = () => {
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery('(min-width:600px)');

    const [captchaValue, setCaptchaValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [newData, setNewData] = useState({
        police_number: ''
    });
    const [responseData, setResponseData] = useState([]);
    const [switchPage, setSwitchPage] = useState(false);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(newData.police_number);
        setLoading(true)

        if (!captchaValue) {
            ErrorAlert({
                message: 'Please complete the CAPTCHA.',
                position: 'top-center'
            });

            setLoading(false)
            return;
        } else {
            // alert("CAPTCHA Matched");
            await axios.post(cekDataMember, {
                police_number: newData.police_number
            }).then((response) => {
                // console.log(response.data);
                if (response.data[0].message === 'data not found') {

                    setTimeout(() => {
                        ErrorAlert({
                            message: 'Data member tidak ditemukan',
                            position: 'top-center'
                        });
                        setLoading(false)
                    }, 2000);
                    // setCaptchaValue(null)
                } else {
                    setResponseData(response.data)
                    SuccessAlert({
                        message: 'Data member ditemukan.',
                        position: 'top-center'
                    });
                    setTimeout(() => {
                        setSwitchPage(true)
                        setLoading(false)
                    }, 3000);
                }
            }).catch((error) => {
                setTimeout(() => {
                    ErrorAlert({
                        message: error.response.data,
                        position: 'top-center'
                    });
                    setLoading(false)
                }, 2000);
                console.log(error.response.data);
            })
        }

        // Continue with form submission
    };


    const handleChange = (e, props) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });
    };


    return (
        switchPage ?
            <MobileDeviceDetailMember
                responseData={responseData}
                switchPage={() => setSwitchPage(false)}
                data={() => setNewData({
                    police_number: ''
                })}
            />
            :
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflow: 'hidden',
                    backgroundColor: '#1111'
                }}
            >
                <Box sx={{ flex: '1.4', height: '100%', position: 'relative' }}>
                    <BackgroundImageMobile />
                </Box>
                <Grid direction="column" justifyContent="center" alignItems="center" sx={{ flex: '4', padding: '20px' }}>
                    <Grid item>
                        <Navbar />
                    </Grid>
                    <Grid item sx={{ marginTop: '40px', marginBottom: '20px' }}>
                        <Typography variant="body1">Masukkan nomor plat atau kode member untuk melakukan perpanjangan member.</Typography>
                    </Grid>
                    <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    type="text"
                                    label="No Plat / Kode Member"
                                    placeholder='DD1234PW'
                                    value={newData.police_number}
                                    name="police_number"
                                    onChange={(e) => handleChange(e)}
                                    autoComplete="off"
                                    required
                                    sx={{
                                        fontSize: { xs: '12px', sm: '13px' },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: '20px', mb: '25px' }}>
                                <ReCAPTCHA
                                    sitekey="6LcZorUoAAAAAAuOGt6yR9PP1KOjfBp6LPngsrui"
                                    onChange={handleCaptchaChange}
                                    size='normal'
                                    theme='light'
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    size="medium"
                                    variant='contained'
                                    sx={{
                                        padding: '10px 0px',
                                        backgroundColor: brandColor,
                                        textTransform: 'none',
                                        width: '100%',
                                        // maxWidth: '100px',
                                        '&:hover': {
                                            color: 'white', // Change text color to white on hover
                                            backgroundColor: brandColor,
                                        },
                                    }}
                                    disabled={loading ? true : false}
                                >
                                    {loading ?
                                        <Icon icon='svg-spinners:bars-scale-fade' style={{ fontSize: '20px' }} />
                                        :
                                        <Typography
                                            sx={{
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            Cek Data Member
                                        </Typography>
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Grid item mt={4}>
                        <Typography variant="body2">Pendaftaran member saat ini hanya dapat dilakukan di Lokasi yang dikelola oleh BSS.</Typography>
                    </Grid>
                </Grid>
                <ToastContainer />
            </Box>
    );
};

export default MobileDevice;
