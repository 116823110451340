import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Modal,
  useMediaQuery,
  Button,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { brandColor, orangeColor, whiteColor } from "../Config/Color";
import moment from "moment";
import getTimelimit from "../Timelimit/Timelimit";
import Timelimit from "../Timelimit/Timelimit";
import hideName from "../HideCharacter/HideCharacter";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import { saveAs } from 'file-saver';
// import Receipt from "../Receipt/Receipt";
import Loading from "../Loading/Loading";
import SuccessAlert from "../ToastyAlert/SuccessAlert";
import Logo from "../../assets/icon/Logo.png"
import BackgroundImg from "../../assets/background/bg-mobile.png"


const ReceiptModal = ({
  open,
  close,
  dataSuccessPayment,
  selectedLocationData,
  switchPage,
  paymentDate,
  data,
  email,
  // newTimeLimit,
  selectedPackage,
  LinkAja,
  Jenius,
  Doku,
  Bsi,
  Dana,
  Shopeepay,
  Mandiri,
  Bca,
  Bni,
  Bri,
  gopay,
  ovo,
}) => {
  const isNonMobile = useMediaQuery("(min-width:620px)");

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('')
  const [finishDate, setFinishDate] = useState('')

  const [hiddenDownloadStrukButton, setHiddenDownloadStrukButton] = useState(false);

  const navigate = useNavigate();
  const strukRef = useRef();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isNonMobile ? 350 : 300,
    // minWidth: 450,
    height: 'auto',
    bgcolor: "white",
    // border: '2px solid #000',
    borderRadius: "10px",
    boxShadow: 24,
    // p: 2,
    // color: "white",
  };


  const handleIssurname = () => {

    if (dataSuccessPayment.additionalInfo.issuerName === 'Shopeepay') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={Shopeepay}
            alt={`Shopeepay`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    } else if (dataSuccessPayment.additionalInfo.issuerName === 'Linkaja') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={LinkAja}
            alt={`LinkAja`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    } else if (dataSuccessPayment.additionalInfo.issuerName === 'Jenius') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={Jenius}
            alt={`Jenius`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    } else if (dataSuccessPayment.additionalInfo.issuerName === 'Doku') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={Doku}
            alt={`Doku`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    } else if (dataSuccessPayment.additionalInfo.issuerName === 'BSI') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={Bsi}
            alt={`Bsi`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    } else if (dataSuccessPayment.additionalInfo.issuerName === 'Dana') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={Dana}
            alt={`Dana`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    } else if (dataSuccessPayment.additionalInfo.issuerName === 'Mandiri') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={Mandiri}
            alt={`Mandiri`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    } else if (dataSuccessPayment.additionalInfo.issuerName === 'BCA') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={Bca}
            alt={`Bca`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    } else if (dataSuccessPayment.additionalInfo.issuerName === 'BNI') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={Bni}
            alt={`Bni`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    } else if (dataSuccessPayment.additionalInfo.issuerName === 'BRI') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={Bri}
            alt={`Bri`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    } else if (dataSuccessPayment.additionalInfo.issuerName === 'OVO') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '5px'
          }}
        >
          <img
            src={ovo}
            alt={`Ovo`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    } else if (dataSuccessPayment.additionalInfo.issuerName === 'GOPAY') {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          <img
            src={gopay}
            alt={`Gopay`}
            width={32}
            height={18}
          />
          <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.issuerName}</Typography>
        </Box>
      )
    }
  }

  const handleUnduhStruk = () => {
    setHiddenDownloadStrukButton(true)
    const element = strukRef.current;

    setTimeout(() => {
      html2canvas(element).then((canvas) => {
        canvas.toBlob((blob) => {
          saveAs(blob, "buktipembayaran.png");
        });
      });
      SuccessAlert({
        message: 'Bukti Pembayaran Berhasil Di Unduh',
        position: 'top-center'
      })
      setHiddenDownloadStrukButton(false)
    }, 2000);
  }

  const handleCreatedPaymentDate = () => {
    const currentDate = new Date()

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: "center",
          mt: "30px",
          mb: '-8px'
        }}
      >
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: 'bold',
            color: 'crimson',
            mb: '5px',
            textAlign: 'center'
          }}
        >
          Note : Perpanjangan member akan berlaku maksimal 3 jam setelah pembayaran
        </Typography>
        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'end',
            gap: '5px'
          }}
        >
          <Icon icon='streamline:web' style={{ fontSize: '14px' }} />
          <Typography
            sx={{
              fontSize: "11px",
              fontWeight: 'bold'
            }}
          >
            Parkways.tech
          </Typography>
        </Box> */}
      </Box>
    )
  }

  // console.log('dataSuccessPaymenta', dataSuccessPayment);
  // console.log('selectedLocationData', selectedLocationData);

  const logos = [
    { top: '110px', left: '50px' },
    { top: '150px', left: '230px' },
    { top: '200px', left: '50px' },
    { top: '250px', left: '100px' },
    { top: '150px', left: '150px' },
    { top: '300px', left: '200px' },
    { top: '350px', left: '20px' },
    { top: '400px', left: '170px' },
    // Tambahkan posisi lain sesuai kebutuhan
  ];

  return (

    <div>
      {dataSuccessPayment && (
        <Modal
          open={open}
          // onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} ref={strukRef}>
            <Box
              sx={{
                // backgroundColor: 'orange',
                width: '100%',
                height: '100%'
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '80px', // Adjust this value based on the height you want for the upper part
                  // width: '100%',
                  // padding: 2,
                  // backgroundColor: brandColor,
                  overflow: 'hidden',
                  borderBottomRightRadius: '5px',
                  borderBottomLeftRadius: '5px',
                  borderTopLeftRadius: hiddenDownloadStrukButton ? undefined : '10px',
                  borderTopRightRadius: hiddenDownloadStrukButton ? undefined : '10px',
                }}
              >
                <img
                  src={BackgroundImg}
                  alt="parkways-background"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    // width: '100%',
                    // height: '100%',
                    // objectFit: 'contain',
                    // objectPosition:'right',
                    zIndex: -1,
                    backgroundColor: brandColor,
                  }}
                />
                {/* <Icon icon='icon-park-outline:success' style={{ fontSize: '80px', color: brandColor }} /> */}
                <img
                  src={Logo}
                  alt="parkways-logo"
                  style={{
                    width: '151.61px',
                    height: '32px',
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    ml: '5px',
                    mb: '6px',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bolder',
                      fontSize: "10px",
                      color: '#fff',
                      letterSpacing: '0.5px'
                    }}
                  >
                    Membership
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  mt: '25px',
                  padding: '0px 20px',
                  // backgroundColor:'green'
                }}
              >
                {/* {logos.map((logo, index) => (
                  <img
                    key={index}
                    src={Logo}
                    alt="parkways-logo"
                    style={{
                      width: '100.61px',
                      height: '20px',
                      position: 'absolute',
                      top: logo.top,
                      left: logo.left,
                      zIndex: -1,
                      opacity: 0.5,
                      transform: 'rotate(-30deg)', // Gantilah -40deg dengan sudut kemiringan yang Anda inginkan
                      // transformOrigin: 'right center' // Memindahkan titik pusat rotasi ke bagian kanan
                    }}
                  />
                ))} */}
                <Box
                  sx={{
                    width: "100%",
                    height: '100%'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 'bold'
                    }}
                  >
                    Data Member
                  </Typography>
                  <Divider
                    // variant="string"
                    sx={{
                      backgroundColor: "#111",
                      borderBottomWidth: 0.5,
                      // mt: "2px",
                    }}
                  />
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Nama Member
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 'bold'
                      }}
                    >
                      {selectedLocationData.name_member ? selectedLocationData.name_member : ''}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Nomor Plat
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 'bold'
                      }}
                    >
                      {selectedLocationData.police_number}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Kode Member
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 'bold'
                      }}
                    >
                      {selectedLocationData.member_code ? selectedLocationData.member_code : ''}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Lokasi
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 'bold'
                      }}
                    >
                      {selectedLocationData.locationname}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Tanggal Mulai
                    </Typography>
                    <Typography
                      sx={{ fontWeight: 'bold', fontSize: '13px' }}
                    >
                      {selectedLocationData.aktif === 'Ya' ? moment(selectedLocationData.time_limit).format('Do MMMM YYYY') : moment(new Date()).format('Do MMMM YYYY')}

                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Tanggal Berakhir
                    </Typography>
                    <Timelimit
                      selectedLocationData={selectedLocationData}
                      selectedPackage={selectedPackage}
                      fontSize='13px'
                      fontWeight='bold'
                    />
                  </Box>

                  <Typography
                    sx={{
                      mt: '15px',
                      fontSize: "14px",
                      fontWeight: 'bold'
                    }}
                  >
                    Rincian Pembayaran
                  </Typography>
                  <Divider
                    // variant="string"
                    sx={{
                      backgroundColor: "#111",
                      borderBottomWidth: 0.5,
                      // mt: "2px",
                    }}
                  />
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Status
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', color: 'darkgreen', fontSize: '13px' }} >{dataSuccessPayment.responseMessage === "Successful" ? 'Sukses' : undefined}</Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Metode Pembayaran
                    </Typography>

                    {dataSuccessPayment.additionalInfo.issuerName ? handleIssurname() : ''}
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      No. Pelanggan
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.additionalInfo.customerNumber}</Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      No. REF
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{dataSuccessPayment.originalReferenceNo}</Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Tanggal Pembelian
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{paymentDate ? moment(paymentDate).format('YYYY/MM/DD') : ''}</Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Waktu Pembelian
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >{paymentDate ? moment(paymentDate).format('HH:mm:ss') : ''}</Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: '5px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                      }}
                    >
                      Total
                    </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }} >Rp. {dataSuccessPayment.amount.value}</Typography>
                  </Box>
                  {/* <Typography
                    sx={{
                      fontSize: "12px",
                      // fontWeight: 'bold',
                      marginTop: '5px'
                    }}
                  >
                    Tanggal Pembelian {paymentDate ? paymentDate : ''}
                  </Typography> */}
                </Box>
              </Box>
              <Box
                sx={{
                  padding: '0px 20px',
                  marginBottom: '20px'
                }}
              >
                {handleCreatedPaymentDate()}
                {!hiddenDownloadStrukButton ?
                  <Box
                    sx={{
                      display: hiddenDownloadStrukButton ? 'none' : 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: '30px'
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        data()
                        switchPage()
                        close()
                      }}
                      sx={{
                        // backgroundColor: brandColor,
                        color: 'white',
                        fontWeight: 'bold',
                      }}

                    >
                      Kembali
                    </Button>
                    <LoadingButton
                      // fullWidth
                      onClick={() => handleUnduhStruk()}
                      size="small"
                      loading={hiddenDownloadStrukButton}
                      loadingIndicator={
                        <Icon
                          icon="svg-spinners:6-dots-scale-middle"
                          color={whiteColor}
                          style={{ fontSize: "25px" }}
                        />
                      }
                      variant="contained"
                      // color='warning'
                      sx={{
                        // mt: '15px',
                        backgroundColor: brandColor,
                        color: whiteColor,
                        fontWeight: 'bold',
                        // width: '60%',
                        "&:hover": { // Styling saat tombol dihover
                          backgroundColor: orangeColor, // Warna background saat hover
                          color: 'white', // Warna huruf saat hover menjadi putih
                        },
                      }}
                    >
                      Unduh
                      <Icon icon='fluent:receipt-32-regular' style={{ fontSize: '20px', marginLeft: '5px' }} />
                    </LoadingButton>
                  </Box>
                  :
                  undefined
                }
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default ReceiptModal;
