import { Navigate, useRoutes } from 'react-router-dom';
import ExtendMember from './pages/extendmember/ExtendMember';
import FAQ from './pages/faq/FAQ';
import Lokasi from './pages/lokasi/Lokasi';
import Layout from './pages/layout';
import DetailMember from './pages/extendmember/DetailMember';
// layouts


// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
      {
          path: '/',
          element: <ExtendMember />,
      },
      {
          // path: 'dashboard',
          //   element: <DashboardLayout />,
          children: [
              { path: '/', element: <ExtendMember />, index: true },
              { path: '/faq', element: <FAQ />, index: true },
              { path: '/lokasi', element: <Lokasi />, index: true },
              { path: '/detailmember', element: <DetailMember />, index: true },
          ],
      },
  ]);


  return routes;
}


