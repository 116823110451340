
// const url = 'http://localhost:84'
const url = 'https://apimember.parkways.id'
// const url = 'http://192.168.8.199:8004'
// const url = 'http://192.168.1.1'

// Doku URL
export const dokuProduction = 'https://my.dokuwallet.com/dokupay/h2h/signon'
export const dokuStaging = 'https://staging.doku.com/dokupay/h2h/signon'


// Current User Login
export const userLogin = `${url}/api/user-profile`;
export const kasirLogin = `${url}/api/loginKasir`;

// Cek member
export const cekDataMember = `${url}/api/selectMember`;

//Create Trasaction
export const membertransactions = `${url}/api/membertransactions`;

// Check Payment
export const checkPayment = `${url}/api/checkInquiryPayment`;

// Insert Data
export const extendtoonline = `${url}/api/extendtoonline`;

// WebSocket URL
export const wsURL = 'ws://localhost:8080';

