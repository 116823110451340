import React, { useState } from 'react';
import { Box, Button, Card, Checkbox, Divider, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import BackgroundImagePC from '../../components/BackgroundImg/BackgroundImgPC';
import Navbar from '../../components/navbar/Navbar';
import BackgroundImageMobile from '../../components/BackgroundImg/BackgroundImgMobile';
import { brandColor, orangeColor } from '../../components/Config/Color';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ComputerDetailMember from '../../components/responsive/detailmember/ComputerDetailMember';
import TabletDeviceDetailMember from '../../components/responsive/detailmember/TabletDeviceDetailMember';
import MobileDeviceDetailMember from '../../components/responsive/detailmember/MobileDeviceDetailMember';

const DetailMember = () => {
    const isMobileDevice = useMediaQuery('(min-width:620px)');
    const isTabletDevice = useMediaQuery('(min-width:768px )');
    const isPCDevice = useMediaQuery('(min-width:1025px)');
    return (
        <>
            {isPCDevice ? <ComputerDetailMember />
                :
                isTabletDevice ? <TabletDeviceDetailMember />
                    :
                    <MobileDeviceDetailMember />
            }

        </>
    );
};

export default DetailMember;
