import React, { useRef, useState } from 'react';
import { Box, Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import BackgroundImagePC from '../../BackgroundImg/BackgroundImgPC';
import Navbar from '../../navbar/Navbar';
import BackgroundImageMobile from '../../BackgroundImg/BackgroundImgMobile';
import { brandColor, orangeColor } from '../../Config/Color';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import SuccesAlert from '../../ToastyAlert/SuccessAlert';
import axios from 'axios';
import { cekDataMember } from '../../Config/Api';
import ComputerDetailMember from '../detailmember/ComputerDetailMember';



const Computer = () => {
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery('(min-width:600px)');

    const [captchaValue, setCaptchaValue] = useState(null);
    const [newData, setNewData] = useState({
        police_number: ''
    });
    const [responseData, setResponseData] = useState([]);
    const [switchPage, setSwitchPage] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(newData.police_number);
        setLoading(true)

        if (captchaValue) {
            await axios.post(cekDataMember, {
                police_number: newData.police_number
            }).then((response) => {
                // console.log(response.data);
                if (response.data[0].message === 'data not found') {
                    setTimeout(() => {
                        ErrorAlert({
                            message: 'Data member tidak ditemukan',
                            position: 'top-center'
                        });
                        setLoading(false)
                    }, 2000);
                    // setCaptchaValue(null)
                } else {
                    setResponseData(response.data)
                    SuccesAlert({
                        message: 'Data member ditemukan.',
                        position: 'top-center'
                    });
                    setTimeout(() => {
                        setSwitchPage(true)
                        setLoading(false)
                    }, 3000);
                }
            }).catch((error) => {
                ErrorAlert({
                    message: error.response.data,
                    position: 'top-center'
                });
                setLoading(false)
                console.log(error.response.data);
            })
        } else {
            setTimeout(() => {
                ErrorAlert({
                    message: 'Silahkan centang CAPTCHA',
                    position: 'top-center'
                })
                setLoading(false)

            }, 1500);
        }



    };

    const handleChange = (e, props) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });
    };



    return (
        <>
            {switchPage ?
                <ComputerDetailMember
                    responseData={responseData}
                    switchPage={() => setSwitchPage(false)}
                    data={() => setNewData({
                        police_number: ''
                    })}
                    resetCaptcha={() => setCaptchaValue(null)}
                />
                :
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        // rowSpacing={2}
                        // columnSpacing={2.75}
                        sx={{
                            height: '100%',
                            overflow: 'hidden'
                        }}
                    >
                        <Grid item xs={12} md={5.5} sx={{ height: isNonMobile ? '100%' : '30%' }}>
                            <BackgroundImagePC />
                        </Grid>
                        <Grid item xs={12} md={6.3}>
                            <Box
                                sx={{
                                    marginTop: "50px",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '10px',
                                    width: '100%',
                                    height: '100%',
                                    // backgroundColor:'orange'
                                }}
                            >

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'end',
                                        alignItems: 'end',
                                        // backgroundColor: 'green',
                                        gap: "20px"
                                    }}
                                >
                                    <Navbar />
                                </Box>
                                <Box
                                    sx={{
                                        flex: '3',
                                        // backgroundColor: 'blue'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            height: "80%",
                                            width: "100%",
                                            // ml: '30px',
                                            justifyContent: 'center',
                                            alignItems: "center",
                                            // backgroundColor: 'blue'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: "center",
                                                height: "80vh",
                                                width: '60%',
                                                // backgroundColor: 'orange'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '75%',
                                                }}
                                            >
                                                <Box mt="24px" mb='24px' textAlign='left'>
                                                    <Typography>Masukkan nomor plat atau kode member</Typography>
                                                    <Typography>untuk melakukan perpanjangan member.</Typography>
                                                </Box>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    type="text"
                                                    label="No Plat / Kode Member"
                                                    placeholder='DD1234PW'
                                                    value={newData.police_number}
                                                    name="police_number"
                                                    onChange={(e) => handleChange(e)}
                                                    autoComplete="off"
                                                    required
                                                    sx={{
                                                        gridColumn: "span 4",
                                                        fontSize: "13px",
                                                        "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "white",
                                                        },
                                                        "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                            "& fieldset": {
                                                                borderBottomColor: brandColor,
                                                            },
                                                        },
                                                        "& .MuiInputLabel-root.Mui-disabled": {
                                                            color: brandColor,
                                                        },
                                                        "& .MuiFilledInput-underline:before": {
                                                            borderBottomColor: brandColor,
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: '#B3B3D4',
                                                            fontWeight: 'bold'
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: brandColor,
                                                        },
                                                        "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                            color: brandColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:before": {
                                                            borderBottomColor: brandColor,
                                                        },
                                                        "& .MuiFilledInput-underline:hover:after": {
                                                            borderBottomColor: brandColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus": {
                                                            borderBottomColor: brandColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:before": {
                                                            borderBottomColor: brandColor,
                                                        },
                                                        "& .MuiFilledInput-underline:focus:after": {
                                                            borderBottomColor: brandColor,
                                                        },
                                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            "-webkit-appearance": "none",
                                                            margin: 0,
                                                        },
                                                        "& input[type=number]": {
                                                            "-moz-appearance": "textfield",
                                                        },
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        mt: '20px',
                                                        mb: '20px'
                                                    }}
                                                >
                                                    <ReCAPTCHA
                                                        sitekey="6LcZorUoAAAAAAuOGt6yR9PP1KOjfBp6LPngsrui"
                                                        onChange={handleCaptchaChange}
                                                        size='normal' // Adjust size as needed
                                                        theme='light'
                                                    />
                                                </Box>
                                                <Button
                                                    fullWidth
                                                    type="submit"
                                                    size="medium"
                                                    variant='contained'
                                                    sx={{
                                                        padding: '10px 0px',
                                                        backgroundColor: brandColor,
                                                        textTransform: 'none',
                                                        width: '100%',
                                                        // maxWidth: '100px',
                                                        '&:hover': {
                                                            color: 'white', // Change text color to white on hover
                                                            backgroundColor: brandColor,
                                                        },
                                                    }}
                                                    disabled={loading ? true : false}
                                                >
                                                    {loading ?
                                                        <Icon icon='svg-spinners:bars-scale-fade' style={{ fontSize: '20px' }} />
                                                        :
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                fontSize: '16px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '500',
                                                                lineHeight: 'normal',
                                                            }}
                                                        >
                                                            Cek Data Member
                                                        </Typography>
                                                    }
                                                </Button>

                                                <Box mt="24px" textAlign='left'>
                                                    <Typography>Pendaftaran member saat ini hanya dapat</Typography>
                                                    <Typography>dilakukan di Lokasi yang dikelola oleh BSS.</Typography>
                                                </Box>
                                            </Box>
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                        </Grid >
                        <ToastContainer style={{ width: 'auto', minWidth: '300px' }} />
                    </Grid >
                </form>
            }

        </>

    );
};

export default Computer;
