import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate dan useLocation
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
// import { Icon } from '@iconify/react';
import { brandColor, whiteColor } from '../Config/Color';

function Navbar() {
  const navigate = useNavigate(); // Gunakan useNavigate untuk navigasi
  const location = useLocation(); // Gunakan useLocation untuk mendapatkan lokasi saat ini

  const [user, setUser] = useState("");

  const item = localStorage.getItem("item");

  const getUsers = () => {
    const username = JSON.parse(item).username;
    setUser(username);
  };

  useEffect(() => {
    if (item) {
      getUsers();
    }
  }, [item]);

  const handleLogout = () => {
    localStorage.clear();
    setUser("");
    navigate('/')
  };

  return (
    <nav style={{height:'100%', display:"flex", justifyContent:'space-between', alignItems:'center', gap:'20px'}}>
        <Button
          onClick={() => { navigate('/'); }}
          variant={location.pathname === '/' || location.pathname === '/detailmember' ? 'contained' : undefined}
          style={{
            backgroundColor: location.pathname === '/' || location.pathname === '/detailmember' ? brandColor : 'transparent',
            // marginRight:"10px",
            borderRadius:'10px'
          }}
          
          >
          <Typography sx={{ fontWeight: 'bold', color: location.pathname === '/' || location.pathname === '/detailmember' ? whiteColor : 'black', fontSize: '14px' }}>perpanjang</Typography>
        </Button>
        <Button
          onClick={() => { navigate('/faq'); }}
          variant={location.pathname === '/faq' ? 'contained' : undefined}
          style={{
            backgroundColor: location.pathname === '/faq' ? brandColor : 'transparent',
            // marginRight:"10px",
            borderRadius:'10px'
          }}
          disabled
          >
          <Typography sx={{ fontWeight: 'bold', color: location.pathname === '/faq' ? whiteColor : 'black', fontSize: '14px' }}>FAQ</Typography>
        </Button>
        <Button
          onClick={() => { navigate('/lokasi'); }}
          variant={location.pathname === '/lokasi' ? 'contained' : undefined}
          style={{
            backgroundColor: location.pathname === '/lokasi' ? brandColor : 'transparent',
            borderRadius:'10px'
          }}
          disabled
        >
          <Typography sx={{ fontWeight: 'bold', color: location.pathname === '/lokasi' ? whiteColor : 'black', fontSize: '14px' }}>Lokasi</Typography>
        </Button>
    </nav>
  );
}

export default Navbar;
