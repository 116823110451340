import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    TextField,
    Typography,
    Modal,
    useMediaQuery,
    Button,
    Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { brandColor, orangeColor, whiteColor } from "../Config/Color";
import moment from "moment";
// import Receipt from "../Receipt/Receipt";
import SuccessAlert from "../ToastyAlert/SuccessAlert";
import ErrorAlert from "../ToastyAlert/ErrorAlert";
import { blue, green } from "@mui/material/colors";



const PaymentNotifModal = ({
    open,
    open2,
    close,
    close2,
    statusModal,
    countdown,
    statusPembayaranTrue,
}) => {
    const isNonMobile = useMediaQuery("(min-width:620px)");
    const isNonTablet = useMediaQuery("(min-width:1000px)");

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: isNonMobile ? 400 : 300,
        // minWidth: 450,
        height: 'auto',
        bgcolor: "white",
        borderRadius: "10px",
        boxShadow: 24,
        p: 2,
        // margin:'10px'
    };

    const [loading, setLoading] = useState(false)

    return (
        <div>
            <Modal
                open={statusModal ? open2 : open}
            // onClose={close}
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Icon icon='material-symbols:error' style={{ fontSize: '90px', color: brandColor }} />
                        <Typography
                            sx={{
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 'bolder',
                                lineHeight: 'normal',
                                color: brandColor
                            }}
                        >
                            Pemberitahuan
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'left',
                            marginTop: '10px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: isNonMobile ? '14px' : '13px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '20px',
                                color: 'black',
                                margin: '10px 0px 0px 0px',
                            }}
                        >
                            Langkah-langkah untuk melakukan pembayaran.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            margin: '5px 0px 0px 20px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: isNonMobile ? '14px' : '12px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '21px',
                                color: 'black',
                                textAlign: 'justify',
                            }}
                        >
                            {isNonMobile && isNonTablet ?
                                <ol>
                                    <li>Silahkan scan Kode QR dan lakukan pembayaran melalui aplikasi pihak ke 3. seperti{' '}
                                        <span style={{ fontWeight: 'bold', color: '#EE4D2D' }}>Shopeepay</span>,{' '}
                                        <span style={{ fontWeight: 'bold', color: blue[700] }}>Dana</span>,{' '}
                                        <span style={{ fontWeight: 'bold', color: green[700] }}>Gopay</span>,{' '}
                                        <span style={{ fontWeight: 'bold', color: "darkblue" }}>BRImo</span>,{' '}
                                        <span style={{ fontWeight: 'bold', color: '#0d396c' }}>Livin</span>,{' '} dll.
                                    </li>
                                    <li>Setelah pembayaran berhasil, silahkan kembali ke website <span style={{ fontWeight: 'bold', color: brandColor }}>Parkways Membership</span> dan tekan tombol <span style={{ fontWeight: 'bold', color: brandColor }}>Konfirmasi Pembayaran</span>.</li>
                                    <li>Bukti Pembayaran Anda akan tampil, silahkan Unduh dan Simpan Bukti Pembayaran Anda sebagai pengganti Kwitansi Member.</li>
                                </ol>
                                :
                                <ol>
                                    <li>Silahkan tekan tombol <span style={{ fontWeight: 'bold', color: brandColor }}>Download QR</span>.</li>
                                    <li>Kemudian buka aplikasi pihak ke 3 yang Anda gunakan untuk melakukan pembayaran. Seperti{' '}
                                        <span style={{ fontWeight: 'bold', color: '#EE4D2D' }}>Shopeepay</span>,{' '}
                                        <span style={{ fontWeight: 'bold', color: blue[700] }}>Dana</span>,{' '}
                                        <span style={{ fontWeight: 'bold', color: green[700] }}>Gopay</span>,{' '}
                                        <span style={{ fontWeight: 'bold', color: "darkblue" }}>BRImo</span>,{' '}
                                        <span style={{ fontWeight: 'bold', color: '#0d396c' }}>Livin</span>,{' '} dll.
                                    </li>
                                    <li>Pada aplikasi pembayaran yang Anda gunakan, pilih menu QRIS.</li>
                                    <li>Pada menu Pembayaran QRIS tekan tombol upload gambar dan silahkan upload gambar QR yang telah di download sebelumnya.</li>
                                    <li>Setelah gambar terupload, secara otomatis akan tampil total harga dan detail pembayaran.</li>
                                    <li>Setelah pembayaran berhasil, silahkan kembali ke website <span style={{ fontWeight: 'bold', color: brandColor }}>Parkways Membership</span> dan tekan tombol <span style={{ fontWeight: 'bold', color: brandColor }}>Konfirmasi Pembayaran</span>.</li>
                                    <li>Bukti Pembayaran Anda akan tampil, silahkan Unduh dan Simpan Bukti Pembayaran Anda sebagai pengganti Kwitansi Member.</li>
                                </ol>
                            }
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '30px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '13px',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                lineHeight: '18px',
                                color: 'maroon',
                                textAlign: 'center',
                            }}
                        >
                            Setelah berhasil melakukan pembayaran wajib menekan tombol
                            Konfirmasi Pembayaran untuk mendapatkan Bukti Pembayaran Anda.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            marginTop: '20px',
                            // backgroundColor: 'orange',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            fullWidth
                            onClick={() => {
                                setLoading(true)
                                if (statusModal) {
                                    setTimeout(() => {
                                        setLoading(false)
                                        close2()
                                    }, 1500);
                                } else {
                                    setTimeout(() => {
                                        setLoading(false)
                                        close()
                                        countdown()
                                        statusPembayaranTrue()
                                        // checkStatus()
                                    }, 1500);
                                }
                            }}
                            // size="small"
                            variant='contained'
                            sx={{
                                padding: '8px 0px',
                                backgroundColor: brandColor,
                                textTransform: 'none',
                                width: '100%',
                                // maxWidth: '100px',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: brandColor,
                                },
                            }}
                            disabled={loading ? true : false}
                        >
                            {loading ?
                                <Icon icon='svg-spinners:bars-scale-fade' style={{ fontSize: '20px' }} />
                                :
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                    }}
                                >
                                    Mengerti
                                </Typography>
                            }
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
};

export default PaymentNotifModal;
