const hideName = (name) => {
  // Memisahkan string menjadi array dengan spasi sebagai pemisah
  const fullNameArray = name.split(' ');

  const replaceSpecialCharacter = (str) => {
    // Ganti karakter khusus di dalam string
    return str.replace(/\//g, ' '); // Ganti '/' dengan karakter lain (misalnya, '_')
  };

  if (fullNameArray.length === 1) {
    // Jika hanya ada nama depan (hanya satu kata)
    const firstName = fullNameArray[0];

    const visibleCharacters = firstName.length <= 1 ? 0 : firstName.length <= 3 ? 1 : firstName.length <= 5 ? 2 : 3; // Jumlah karakter yang ingin ditampilkan

    const hiddenCharactersFirstName = firstName.length - visibleCharacters;
    const hiddenPartFirstName = '*'.repeat(hiddenCharactersFirstName);

    const visiblePartFirstname = replaceSpecialCharacter(firstName.slice(0, visibleCharacters)) + hiddenPartFirstName;

    return visiblePartFirstname;
  } else if (fullNameArray.length === 2) {
    // Jika ada nama depan dan nama belakang
    const firstName = fullNameArray[0];
    const lastName = fullNameArray[1];

    const visibleCharacters = firstName.length <= 1 ? 0 : firstName.length <= 3 ? 1 : firstName.length <= 5 ? 2 : 3; // Jumlah karakter yang ingin ditampilkan
    const visibleCharactersLastname = lastName.length <= 1 ? 0 : lastName.length <= 3 ? 1 : lastName.length <= 5 ? 2 : 3; // Jumlah karakter yang ingin ditampilkan

    const hiddenCharactersFirstName = firstName.length - visibleCharacters;
    const hiddenCharactersLastName = lastName.length - visibleCharactersLastname;

    const hiddenPartFirstName = '*'.repeat(hiddenCharactersFirstName);
    const hiddenPartLastName = '*'.repeat(hiddenCharactersLastName);

    const visiblePartFirstname = replaceSpecialCharacter(firstName.slice(0, visibleCharacters)) + hiddenPartFirstName;
    const visiblePartLastname = replaceSpecialCharacter(lastName.slice(0, visibleCharactersLastname)) + hiddenPartLastName;

    return visiblePartFirstname + ' ' + visiblePartLastname;
  } else if (fullNameArray.length === 3) {
    // Jika ada tiga kata
    const firstName = fullNameArray[0];
    const middleName = fullNameArray[1];
    const lastName = fullNameArray[2];

    const visibleCharacters = firstName.length <= 1 ? 0 : firstName.length <= 3 ? 1 : firstName.length <= 5 ? 2 : 3; // Jumlah karakter yang ingin ditampilkan
    const visibleCharactersMiddleName = middleName.length <= 1 ? 0 : middleName.length <= 3 ? 1 : middleName.length <= 5 ? 2 : 3; // Jumlah karakter yang ingin ditampilkan
    const visibleCharactersLastname = lastName.length <= 1 ? 0 : lastName.length <= 3 ? 1 : lastName.length <= 5 ? 2 : 3; // Jumlah karakter yang ingin ditampilkan

    const hiddenCharactersFirstName = firstName.length - visibleCharacters;
    const hiddenCharactersMiddleName = middleName.length - visibleCharactersMiddleName;
    const hiddenCharactersLastName = lastName.length - visibleCharactersLastname;

    const hiddenPartFirstName = '*'.repeat(hiddenCharactersFirstName);
    const hiddenPartMiddleName = '*'.repeat(hiddenCharactersMiddleName);
    const hiddenPartLastName = '*'.repeat(hiddenCharactersLastName);

    const visiblePartFirstname = replaceSpecialCharacter(firstName.slice(0, visibleCharacters)) + hiddenPartFirstName;
    const visiblePartMiddleName = replaceSpecialCharacter(middleName.slice(0, visibleCharactersMiddleName)) + hiddenPartMiddleName;
    const visiblePartLastname = replaceSpecialCharacter(lastName.slice(0, visibleCharactersLastname)) + hiddenPartLastName;

    return visiblePartFirstname + ' ' + visiblePartMiddleName + ' ' + visiblePartLastname;
  } else {
    // Jika lebih dari tiga kata (misalnya, nama tengah juga ada), kembalikan nama asli
    return name;
  }
};

export default hideName;
