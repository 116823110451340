import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Checkbox, Divider, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import Navbar from '../../navbar/Navbar';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import BackgroundImagePC from '../../BackgroundImg/BackgroundImgPC';
import { brandColor, orangeColor, whiteColor } from '../../Config/Color';
import moment, { duration } from 'moment/moment';
import 'moment/locale/id';
import axios from 'axios';
import { checkPayment, extendtoonline, membertransactions } from '../../Config/Api';
import convertToDecimalWithTwoDigits from '../../Convert/ConvertToDecimal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import hideName from '../../HideCharacter/HideCharacter.jsx';
import ConvertIDR from '../../../components/Convert/ConvertIDR.jsx'
import QRCode from "react-qr-code";
import LinkAja from '../../../assets/icon/QRIS/linkaja.png'
import Jenius from '../../../assets/icon/QRIS/jenius.png'
import Doku from '../../../assets/icon/QRIS/doku.png'
import Bsi from '../../../assets/icon/QRIS/bsi.png'
import Dana from '../../../assets/icon/QRIS/dana.png'
import Shopeepay from '../../../assets/icon/QRIS/shopeepay.png'
import Mandiri from '../../../assets/icon/QRIS/mandiri.png'
import Bca from '../../../assets/icon/QRIS/bca.png'
import Bni from '../../../assets/icon/QRIS/bni.png'
import Bri from '../../../assets/icon/QRIS/bri.png'
import gopay from '../../../assets/icon/QRIS/gopay.png'
import ovo from '../../../assets/icon/QRIS/ovo.png'
import ReceiptModal from '../../modal/ReceiptModal.jsx';
import ErrorAlert from '../../ToastyAlert/ErrorAlert.js';
import SuccessAlert from '../../ToastyAlert/SuccessAlert.js';
import Timelimit from '../../Timelimit/Timelimit.jsx';
import { LoadingButton } from '@mui/lab';
import PaymentNotifModal from '../../modal/PaymentNotifModal.jsx';
// import { wsURL } from '../../../components/Config/Api.js';
moment.locale('id');

const ComputerDetailMember = ({ responseData, switchPage, data }) => {
    // console.log('responseData',responseData);
    const [selectedLocationData, setSelectedLocationData] = useState(responseData.length > 0 ? responseData[0] : []);

    const [paymentPage, setPaymentPage] = useState(false)
    const [dataPaket, setDataPaket] = useState(responseData.length > 0 ? responseData[0].member_categories : [])
    const [selectedPackage, setSelectedPackage] = useState({
        id_membercategorylocations: '',
        price: '',
        duration: 0
    })
    const [dataQRIS, setDataQRIS] = useState({
        transaction_code: '',
        referenceNo: '',
        qrContent: '',
        access_token: '',
        partnerReferenceNo: '',
        responseCode: '',
        responseMessage: ''
    })
    const [countdown, setCountdown] = useState(0); // 5 minutes = 300 seconds + 10 seconds (initial)
    const [loading, setLoading] = useState(false)
    const [loadingKonfirmasiPembayaran, setLoadingKonfirmasiPembayaran] = useState(false)
    const [loadingQRIS, setLoadingQRIS] = useState(false)
    const [loadingRefreshQRIS, setLoadingRefreshQRIS] = useState(false)
    const [OpenReceiptModal, setOpenReceiptModal] = useState(false)
    const [dataSuccessPayment, setDataSuccessPayment] = useState('')
    const [checkStatus, setCheckStatus] = useState(false)
    const [newTimeLimit, setNewTimeLimit] = useState('')
    const [paymentDate, setPaymentDate] = useState('')
    const [startDate, setStartDate] = useState(() => {
        if (selectedLocationData.aktif === 'Ya') {
            let currentTimelimit = new Date(selectedLocationData.time_limit);
            currentTimelimit.setDate(currentTimelimit.getDate() + 1);
            return currentTimelimit;
        } else {
            let currentTimelimit = new Date();
            currentTimelimit.setDate(currentTimelimit.getDate());
            return currentTimelimit;
        }
    });

    const [openPaymentNotifModal, setOpenPaymentNotifModal] = useState(true);
    const [openPaymentNotifModal2, setOpenPaymentNotifModal2] = useState(true);
    const [statusModal, setStatusModal] = useState(false);
    const [statusPembayaran, setStatusPembayaran] = useState(false);

    // console.log('dataPaket',dataPaket);
    // console.log('selectedLocationData',selectedLocationData);

    // const socket = new WebSocket(wsURL);

    // socket.onopen = () => {
    //     console.log('Connected to WebSocket server');
    // };

    // socket.onclose = () => {
    //     console.log('Disconnected from WebSocket server');
    // };

    useEffect(() => {
        if (paymentPage) {
            setOpenPaymentNotifModal(true)
        }
    }, [paymentPage])

    useEffect(() => {
        const timer = setInterval(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [countdown]);

    // console.log('loading', loadingKonfirmasiPembayaran);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingQRIS(true)
        setLoading(true)

        const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

        // console.log('id_userlocations', selectedLocationData.id_userlocations);
        // console.log('member_id', selectedLocationData.id);
        // console.log('category_id', selectedPackage.id_membercategorylocations);
        // console.log('currentDate', currentDate);
        // console.log('price', convertToDecimalWithTwoDigits(selectedPackage.price));
        // console.log('QRIS');
        // console.log('Pending');

        if (selectedPackage.price > 0) {
            await axios.post(membertransactions, {
                id_userlocations: selectedLocationData.id_userlocations,
                member_id: selectedLocationData.id,
                category_id: selectedPackage.id_membercategorylocations,
                purchase_time: currentDate,
                total: convertToDecimalWithTwoDigits(selectedPackage.price),
                payment_type: 'QRIS',
                payment_status: 'Pending'
            }).then((response) => {
                // console.log(response.data);
                if (response.data.error === 'Invalid response qris') {
                    ErrorAlert({
                        message: response.data.error,
                        position: 'top-center'
                    })

                } else {
                    setPaymentPage(true)
                    if (!openPaymentNotifModal) {
                        setCountdown(300)
                    }
                    setDataQRIS({
                        transaction_code: response.data?.transaction_code,
                        referenceNo: response.data?.referenceNo,
                        qrContent: response.data?.qrContent,
                        access_token: response.data?.access_token,
                        partnerReferenceNo: response.data?.partnerReferenceNo,
                        responseCode: response.data?.responseCode,
                        responseMessage: response.data?.responseMessage
                    })
                }
                setLoadingQRIS(false)
                setLoading(false)
            }).catch((error) => {
                console.log(error.response.data);
                setLoadingQRIS(false)
                setLoading(false)
            })
        } else if (selectedPackage.price === '') {
            ErrorAlert({
                message: 'Paket wajib dipilih',
                position: 'top-center'
            })
            setLoadingQRIS(false)
            setLoading(false)
        } else if (selectedPackage.price) {
            ErrorAlert({
                message: 'Paket tidak boleh 0 rupiah',
                position: 'top-center'
            })
            setLoadingQRIS(false)
            setLoading(false)
        }
    }

    const handleSendDataToPwsOffstreet = async () => {

        let extendMemberTimelimit = ''
        const currentDate = new Date()
        const currentTimelimit = new Date(selectedLocationData.time_limit)
        if (selectedLocationData.aktif === 'Ya') {
            // extendMemberTimelimit = currentTimelimit.setDate(currentTimelimit.getDate() + selectedPackage.duration)
            extendMemberTimelimit = new Date(selectedLocationData.time_limit);
            extendMemberTimelimit.setDate(
                extendMemberTimelimit.getDate() +
                selectedPackage.duration,
            );
        } else {
            extendMemberTimelimit = new Date(currentDate);
            extendMemberTimelimit.setDate(
                extendMemberTimelimit.getDate() +
                selectedPackage.duration - 1,
            );
            // extendMemberTimelimit = currentDate.setDate(currentDate.getDate() + selectedPackage.duration)
        }
        // console.log('selectedLocationData.id', selectedLocationData.id);
        // console.log('id_membercategorylocations', selectedPackage.id_membercategorylocations);
        // console.log('start date', selectedLocationData.aktif === 'Ya' ? moment(selectedLocationData.time_limit).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),);
        // console.log('currentTimelimit', moment(extendMemberTimelimit).format('YYYY-MM-DD'));
        // console.log('total', selectedPackage.price);
        // console.log('id_userlocations', selectedLocationData.id_userlocations);


        await axios.post(extendtoonline, {
            member_id: selectedLocationData.id,
            category_id: selectedPackage.id_membercategorylocations,
            start_date: selectedLocationData.aktif === 'Ya' ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
            finish_date: moment(extendMemberTimelimit).format('YYYY-MM-DD'),
            total: selectedPackage.price,
            id_userlocations: selectedLocationData.id_userlocations
        }).then((response) => {
            const date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            setPaymentDate(date)
            setLoadingKonfirmasiPembayaran(false)
            console.log(response.data);
            SuccessAlert({
                message: 'Member berhasil diperpanjang',
                position: 'top-center'
            })
            setOpenReceiptModal(true)
            setLoadingKonfirmasiPembayaran(false)
            setStatusPembayaran(false)
            setStatusModal(false)

            // Send data via WebSocket
            // if (socket.readyState === WebSocket.OPEN) {
            //     socket.send(JSON.stringify({
            //         member_id: selectedLocationData.id,
            //         category_id: selectedPackage.id_membercategorylocations,
            //         start_date: selectedLocationData.aktif === 'Ya' ? moment(startDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
            //         finish_date: moment(extendMemberTimelimit).format('YYYY-MM-DD'),
            //         total: selectedPackage.price,
            //         id_userlocations: selectedLocationData.id_userlocations
            //     }));
            // }
        }).catch((error) => {
            setLoadingKonfirmasiPembayaran(false)
            // console.log(error.response.data);
        })
    }

    // Mengatur interval untuk mengecek pembayaran setiap 5 detik
    // useEffect(() => {
    //     if (checkStatus) {
    //         console.log('checkStatus', checkStatus);
    //         const checkPaymentInterval = setInterval(() => {
    //             handleCheckingPayment();
    //         }, 10000);

    //         return () => {
    //             clearInterval(checkPaymentInterval);
    //         };
    //     }
    // }, [checkStatus]);

    // const handleCheckingPayment = async () => {
    //     console.log('checkpayment otomatis');
    //     console.log('transaction_code', dataQRIS.transaction_code);
    //     console.log('referenceNo', dataQRIS.referenceNo);

    //     await axios.post(checkPayment, {
    //         transaction_code: dataQRIS.transaction_code,
    //         referenceNo: dataQRIS.referenceNo
    //     }).then((response) => {
    //         console.log('asd', response.data);
    //         if (response.data.responseMessage === 'Successful') {
    //             setCheckStatus(false)
    //             setDataSuccessPayment(response.data)

    //             handleSendDataToPwsOffstreet()
    //         } else if (response.data.responseMessage === 'General Error') {
    //             setCheckStatus(false)
    //         }
    //         // console.log('123', response.data);
    //     }).catch((error) => {
    //         console.log('error', error.response.data);
    //         setLoadingKonfirmasiPembayaran(false)
    //     })
    // }

    // console.log('countdown', countdown);

    const handleSubmitPerpanjangMember = async (e) => {
        e.preventDefault();

        setLoadingKonfirmasiPembayaran(true)

        await axios.post(checkPayment, {
            transaction_code: dataQRIS.transaction_code,
            referenceNo: dataQRIS.referenceNo
        }).then((response) => {
            if (response.data.responseMessage === 'Transaction Not Found. CPAN data not found') {
                ErrorAlert({
                    message: 'Tolong selesaikan pembayaran.',
                    position: 'top-center'
                });
                setLoadingKonfirmasiPembayaran(false)
            } else if (response.data.responseMessage === 'Successful') {
                // setCheckStatus(false)
                setDataSuccessPayment(response.data)

                handleSendDataToPwsOffstreet()
            } else if (response.data.responseMessage === 'Transaction still on process') {
                ErrorAlert({
                    message: 'Transaksi sedang diproses',
                    position: 'top-center',
                })
                setLoadingKonfirmasiPembayaran(false)
            }
            // console.log('123', response.data);
        }).catch((error) => {
            console.log(error.response.data);
            setLoadingKonfirmasiPembayaran(false)
        })
    }

    const handleLocationChange = (event) => {
        const selectedLocationName = event.target.value;
        const selectedLocation = responseData.find((data) => data.locationname === selectedLocationName);
        setSelectedLocationData(selectedLocation);
        setDataPaket(selectedLocation.member_categories);
    };

    const handleSelectPackage = (event, id, price, duration) => {
        setSelectedPackage({
            id_membercategorylocations: id,
            price: price,
            duration: duration
        })
    };

    // console.log('statusPembayaran', statusPembayaran);

    return (
        responseData.length && paymentPage ?
            <Grid
                container
                // rowSpacing={2}
                // columnSpacing={2.75}
                sx={{
                    height: '100%',
                    overflow: 'hidden'
                }}
            >
                <Grid item xs={5.5} sx={{ height: '100%' }}>
                    <BackgroundImagePC />
                </Grid>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            marginTop: "35px",
                            display: 'flex',
                            flexDirection: 'column',
                            // gap: '10px',
                            width: '100%',
                            // height: 'auto',
                            // backgroundColor:'blue'
                        }}
                    >
                        <form onSubmit={handleSubmitPerpanjangMember}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'end',
                                    alignItems: 'end',
                                    // backgroundColor: 'green',
                                    gap: "20px",
                                    // height:'400px',
                                }}
                            >
                                <Navbar />
                            </Box>
                            <Box
                                sx={{
                                    // display: 'flex',
                                    // justifyContent: 'center',
                                    // alignItems: 'center',
                                    // width: '60%',
                                    // height:'100%',
                                    // height:'400px',
                                    // backgroundColor:'orange',
                                    padding: '0 150px',
                                    mt: '20px',
                                    mb: '15px'
                                }}
                            >
                                <Box sx={{ mb: '3px' }}>
                                </Box>
                                <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>Rincian Pembayaran</Typography>
                                <Divider
                                    // variant="string"
                                    sx={{
                                        backgroundColor: "#111",
                                        borderBottomWidth: 0.5,
                                        // mt: "2px",
                                    }}
                                />
                                <Typography sx={{ mt: '20px', mb: '10px', fontSize: '14px', color: '#404040' }}>Detail perpanjangan</Typography>
                                <Card
                                    sx={{ p: '10px 15px', mt: '5px' }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex'
                                        }}
                                    >
                                        <Box
                                            sx={{ flex: '1' }}
                                        >
                                            <Box>
                                                <Typography sx={{ color: '#808080', fontSize: '12px' }}>Nama</Typography>
                                                <Typography sx={{ color: '#404040', fontSize: '14px' }}>
                                                    {selectedLocationData.name_member
                                                        ? hideName(selectedLocationData.name_member)
                                                        : 'Tidak ada data nama member'}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{ mt: '16px' }}
                                            >
                                                <Typography sx={{ color: '#808080', fontSize: '12px' }}>Kode Member</Typography>
                                                <Typography sx={{ color: '#404040', fontSize: '14px' }}>
                                                    {selectedLocationData.member_code
                                                        ? hideName(selectedLocationData.member_code)
                                                        : 'Tidak ada data kode member'}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{ mt: '16px' }}
                                            >
                                                <Typography sx={{ color: '#808080', fontSize: '12px' }}>Tanggal Mulai</Typography>
                                                <Typography sx={{ color: '#404040', fontSize: '14px' }}>{selectedLocationData.time_limit ? moment(startDate).format('Do MMMM YYYY') : 'Tidak ada data masa berlaku'}</Typography>
                                            </Box>
                                            <Box
                                                sx={{ mt: '16px' }}
                                            >
                                                <Typography sx={{ color: '#808080', fontSize: '12px' }}>Total Pembayaran</Typography>
                                                <Typography sx={{ color: '#404040', fontSize: '14px', fontWeight: 'bold' }}>{selectedPackage.price ? `Rp. ${ConvertIDR(selectedPackage.price)}  ` : 0}</Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{ flex: '1' }}
                                        >
                                            <Box>
                                                <Typography sx={{ color: '#808080', fontSize: '12px' }}>No Plat</Typography>
                                                <Typography sx={{ color: '#404040', fontSize: '14px' }}>{selectedLocationData.police_number ? selectedLocationData.police_number : 'Tidak ada data nomor plat'}</Typography>
                                            </Box>
                                            <Box
                                                sx={{ mt: '16px' }}
                                            >
                                                <Typography sx={{ color: '#808080', fontSize: '12px' }}>Lokasi Member Terdaftar</Typography>
                                                <Typography sx={{ color: '#404040', fontSize: '14px' }}>{selectedLocationData.locationname ? selectedLocationData.locationname : 'Tidak ada data lokasi'}</Typography>
                                            </Box>
                                            <Box
                                                sx={{ mt: '16px' }}
                                            >
                                                <Typography sx={{ color: '#808080', fontSize: '14px' }}>Tanggal Berakhir</Typography>
                                                <Timelimit
                                                    selectedLocationData={selectedLocationData}
                                                    selectedPackage={selectedPackage}
                                                    fontSize='12px'
                                                    color='#404040'
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Card>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: '-10px'

                                    }}
                                >
                                    <Typography sx={{ mt: '20px', mb: '10px', fontSize: '14px', color: '#404040' }}>Pembayaran QRIS</Typography>
                                    <Button
                                        onClick={() => {
                                            setStatusModal(true);
                                            setOpenPaymentNotifModal2(true); // Buka modal dengan open2 jika statusModal true
                                            setOpenPaymentNotifModal(false); // Tutup modal dengan open jika statusModal true
                                        }}
                                        size='small'
                                        sx={{
                                            fontSize: '12px',
                                            textTransform: 'none',
                                            margin: '0px',
                                            padding: '0px',
                                            color: brandColor,
                                            fontWeight: 'bold',
                                            marginBottom: '-12px',
                                            textDecoration: 'underline',
                                            "&:hover": {
                                                // backgroundColor: brandColor, // Warna background saat hover
                                                color: brandColor, // Warna huruf saat hover menjadi putih
                                                textDecoration: 'underline',
                                            },
                                        }}
                                    >
                                        Cara Pembayaran
                                    </Button>
                                </Box>
                                <Card
                                    sx={{ p: '10px 15px', mt: '5px' }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: '10px',
                                            // backgroundColor: 'orange'
                                        }}
                                    >
                                        <Box
                                            // align='center'
                                            sx={{
                                                flex: '0.45',
                                                // backgroundColor: 'orange'
                                            }}
                                        >
                                            {countdown > 0 && !openPaymentNotifModal ?
                                                <QRCode
                                                    value={dataQRIS ? dataQRIS.qrContent : ''}
                                                    size={200}
                                                    style={{
                                                        height: "100px",
                                                        maxWidth: "100px",
                                                        width: "100px",
                                                        padding: '10px',
                                                        border: 'solid 2px #D9D9D9',
                                                        // backgroundColor: brandColor,
                                                        borderRadius: '10px',

                                                    }}
                                                    viewBox={`0 0 256 256`}
                                                />
                                                :
                                                <Icon
                                                    icon='tabler:qrcode-off'
                                                    style={{
                                                        height: "100px",
                                                        maxWidth: "100px",
                                                        width: "100px",
                                                        padding: '10px',
                                                        border: 'solid 2px #D9D9D9',
                                                        // backgroundColor: brandColor,
                                                        borderRadius: '10px',
                                                        color: '#d9d9d9'
                                                    }}
                                                />
                                            }
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flex: '1',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                pt: '5px',
                                                pb: '7px',
                                                // backgroundColor: 'blue',
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        color: '#808080',
                                                        fontSize: '12px',
                                                        lineHeight: '14.52px',
                                                        mb: '10px'
                                                    }}
                                                >
                                                    Waktu Pembayaran
                                                </Typography>

                                                {countdown < 1 && !openPaymentNotifModal ? (
                                                    <Typography
                                                        sx={{
                                                            color: '#DC1A1A',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            lineHeight: '16.94px'
                                                        }}
                                                    >
                                                        {statusPembayaran ? 'Pembayaran gagal!' : ''}
                                                    </Typography>
                                                ) : countdown >= 1 && !openPaymentNotifModal ? (
                                                    <Typography
                                                        sx={{
                                                            color: '#DC1A1A',
                                                            fontWeight: '400',
                                                            fontSize: '14px',
                                                            lineHeight: '16.94px'
                                                        }}
                                                    >
                                                        {Math.floor(countdown / 60)} menit {countdown % 60} detik
                                                    </Typography>
                                                ) : undefined}
                                            </Box>
                                            <Box>
                                                {/* <LoadingButton
                                                    // fullWidth
                                                    // type="submit"
                                                    onClick={handleSubmit}
                                                    size="small"
                                                    loading={loadingQRIS}
                                                    loadingIndicator={
                                                        <Icon
                                                            icon="svg-spinners:bars-scale-fade"
                                                            color={brandColor}
                                                            style={{ fontSize: "25px" }}
                                                        />
                                                    }
                                                    variant="contained"
                                                    // color='warning'
                                                    // sx={{
                                                    //     // mt: '15px',
                                                    //     padding: '9px, 15px, 9px, 15px',
                                                    //     borderRadius: '5px',
                                                    //     backgroundColor: brandColor,
                                                    //     color: whiteColor,
                                                    //     fontWeight: 'bold',
                                                    //     width: '117px',
                                                    //     height: '35px',
                                                    //     fontSize: '12px',
                                                    //     "&:hover": { // Styling saat tombol dihover
                                                    //         backgroundColor: brandColor, // Warna background saat hover
                                                    //         color: 'white', // Warna huruf saat hover menjadi putih
                                                    //     },
                                                    // }}
                                                    disabled={countdown < 1 ? false : true}
                                                >
                                                    Refresh QRIS
                                                </LoadingButton> */}
                                                <Button
                                                    // fullWidth
                                                    // type="submit"
                                                    onClick={(e) => handleSubmit(e)}
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: brandColor,
                                                        textTransform: 'none',
                                                        width: '100%',
                                                        maxWidth: '130px',
                                                        '&:hover': {
                                                            color: 'white', // Change text color to white on hover
                                                            backgroundColor: brandColor,
                                                        },
                                                    }}
                                                    disabled={countdown < 1 ? false : true}
                                                >
                                                    {loadingQRIS ?
                                                        <Icon icon='svg-spinners:bars-scale-fade' style={{ fontSize: '20px' }} />
                                                        :
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                fontSize: '14px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '500',
                                                                lineHeight: 'normal',
                                                            }}
                                                        >
                                                            Refresh QR
                                                        </Typography>
                                                    }
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(6, 1fr)',
                                            gridGap: '10px',
                                            mt: '15px',
                                            placeItems: 'center',
                                        }}
                                    >
                                        {/* Define your image sources in an array for easier rendering */}
                                        {[
                                            LinkAja,
                                            Jenius,
                                            Doku,
                                            Bsi,
                                            Dana,
                                            Shopeepay,
                                            Mandiri,
                                            Bca,
                                            Bni,
                                            Bri,
                                            gopay,
                                            ovo,
                                        ].map((logo, index) => (
                                            <img
                                                key={index}
                                                src={logo}
                                                alt={`payment-method-${index}`}
                                                width={42.4}
                                                height={30.29}
                                            />
                                        ))}
                                    </Box>

                                </Card>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        // backgroundColor:'orange'
                                        mt: '15px',
                                    }}
                                >
                                    {/* <LoadingButton
                                        fullWidth
                                        type="submit"
                                        size="medium"
                                        loading={loadingKonfirmasiPembayaran}
                                        loadingIndicator={
                                            <Icon
                                                icon="svg-spinners:bars-scale-fade"
                                                color={brandColor}
                                                style={{ fontSize: "25px" }}
                                            />
                                        }
                                        variant="contained"

                                    // color='warning'
                                    // sx={{
                                    //     mt: '15px',
                                    //     backgroundColor: brandColor,
                                    //     color: whiteColor,
                                    //     fontWeight: 'bold',
                                    //     // width: '60%',
                                    //     "&:hover": { // Styling saat tombol dihover
                                    //         backgroundColor: orangeColor, // Warna background saat hover
                                    //         color: 'white', // Warna huruf saat hover menjadi putih
                                    //     },
                                    // }}
                                    // disabled={countdown > 1 || loadingKonfirmasiPembayaran ? false : true}
                                    >
                                        Konfirmasi Pembayaran
                                    </LoadingButton> */}
                                    <Button
                                        fullWidth
                                        type="submit"
                                        size="medium"
                                        variant='contained'
                                        sx={{
                                            // padding: '10px 0px',
                                            backgroundColor: brandColor,
                                            textTransform: 'none',
                                            width: '100%',
                                            // maxWidth: '100px',
                                            '&:hover': {
                                                color: 'white', // Change text color to white on hover
                                                backgroundColor: brandColor,
                                            },
                                        }}
                                        disabled={countdown > 1 ? false : true}
                                    >
                                        {loadingKonfirmasiPembayaran ?
                                            <Icon icon='svg-spinners:bars-scale-fade' style={{ fontSize: '20px' }} />
                                            :
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal',
                                                }}
                                            >
                                                Konfirmasi Pembayaran
                                            </Typography>
                                        }
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Grid>
                <ReceiptModal
                    open={OpenReceiptModal}
                    close={() => setOpenReceiptModal(false)}
                    dataSuccessPayment={dataSuccessPayment}
                    selectedLocationData={selectedLocationData}
                    paymentDate={paymentDate}
                    LinkAja={LinkAja}
                    Jenius={Jenius}
                    Doku={Doku}
                    Bsi={Bsi}
                    Dana={Dana}
                    Shopeepay={Shopeepay}
                    Mandiri={Mandiri}
                    Bca={Bca}
                    Bni={Bni}
                    Bri={Bri}
                    gopay={gopay}
                    ovo={ovo}
                    selectedPackage={selectedPackage}
                    switchPage={switchPage}
                    data={data}
                />
                <PaymentNotifModal
                    open={openPaymentNotifModal}
                    open2={openPaymentNotifModal2}
                    close={() => setOpenPaymentNotifModal(false)}
                    close2={() => setOpenPaymentNotifModal2(false)}
                    statusModal={statusModal}
                    countdown={() => setCountdown(300)}
                    statusPembayaranTrue={() => setStatusPembayaran(true)}
                // checkStatus={() => setCheckStatus(true)}
                />
                <ToastContainer style={{ width: 'auto', minWidth: '300px' }} />
            </Grid>
            :
            <Grid
                container
                // rowSpacing={2}
                // columnSpacing={2.75}
                sx={{
                    height: '100%',
                    overflow: 'hidden'
                }}
            >
                <Grid item xs={5.5} sx={{ height: '100%' }}>
                    <BackgroundImagePC />
                </Grid>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            marginTop: "35px",
                            display: 'flex',
                            flexDirection: 'column',
                            // gap: '10px',
                            width: '100%',
                            height: '100%',
                            // backgroundColor:'orange'

                        }}
                    >
                        <form onSubmit={handleSubmit}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'end',
                                    alignItems: 'end',
                                    // backgroundColor: 'green',
                                    gap: "20px"
                                }}
                            >
                                <Navbar />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // width: '60%',
                                    // backgroundColor:'orange',
                                    width: '100%',
                                    mt: '40px',
                                    mb: '15px'
                                }}
                            >
                                <Box sx={{ mb: '3px', width: '60%' }}>
                                    <Typography sx={{ fontSize: '16px', fontWeight: '700' }}>Detail Member</Typography>
                                    {responseData.length <= 1 ?
                                        <Typography sx={{ fontSize: '14px', color: '#404040' }}>Silahkan lengkapi data dibawah ini untuk perpanjang member.</Typography>
                                        :
                                        <Typography sx={{ fontSize: '14px', color: '#404040' }}>Sepertinya nomor plat kamu terdaftar lebih dari 1 lokasi parkir kami.</Typography>
                                    }
                                </Box>
                                <Box
                                    sx={{ width: '60%' }}
                                >
                                    <Divider
                                        // variant="string"
                                        sx={{
                                            backgroundColor: "#111",
                                            borderBottomWidth: 0.5,
                                            // mt: "2px",
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    pb: dataPaket.length > 4 ? '5px' : '5px',
                                    overflowY: dataPaket.length > 4 ? 'scroll' : 'auto',
                                    maxHeight: dataPaket.length > 4 ? '530px' : 'auto',
                                    // backgroundColor:'orange',
                                    '&::-webkit-scrollbar': {
                                        width: '4px', // Adjust the width as needed
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: brandColor, // Change this to your brand color
                                        borderRadius: '6px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#f1f1f1', // Change this to the background color
                                        borderRadius: '6px',
                                    },
                                }}
                            >
                                <Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            // height: "100%",
                                            width: "100%",
                                            justifyContent: 'center',
                                            alignItems: "center",
                                            // backgroundColor: 'blue'

                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                // justifyContent: 'center',
                                                // alignItems: "center",
                                                height: "100%",
                                                width: '60%',
                                                // mt: '34px'
                                                // backgroundColor: 'orange'

                                            }}
                                        >
                                            <Box sx={{}}>
                                                <FormControl >
                                                    <FormLabel sx={{ fontSize: '12px', color: '#404040' }}>Pilih Lokasi</FormLabel>

                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        value={selectedLocationData.locationname}
                                                        name="radio-buttons-group"
                                                        onChange={handleLocationChange}
                                                        sx={{
                                                            fontSize: '12px'
                                                        }}
                                                    >
                                                        {responseData.map((data, index) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={index}
                                                                    value={data.locationname}
                                                                    control={<Radio size="small" />}
                                                                    label={<span style={{ fontSize: '14px' }}>{data.locationname ? data.locationname : 'Tidak ada data lokasi'}</span>}
                                                                />
                                                            )
                                                        })}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                            <Card
                                                sx={{ p: '10px 15px', mt: '8px' }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{ flex: '1' }}
                                                    >
                                                        <Box>
                                                            <Typography sx={{ color: '#808080', fontSize: '12px' }}>Nama</Typography>
                                                            <Typography sx={{ color: '#404040', fontSize: '14px' }}>
                                                                {selectedLocationData.name_member
                                                                    ? hideName(selectedLocationData.name_member)
                                                                    : 'Tidak ada data nama member'}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{ mt: '16px' }}
                                                        >
                                                            <Typography sx={{ color: '#808080', fontSize: '12px' }}>Kode Member</Typography>
                                                            <Typography sx={{ color: '#404040', fontSize: '14px' }}>
                                                                {selectedLocationData.member_code
                                                                    ? hideName(selectedLocationData.member_code)
                                                                    : 'Tidak ada data kode member'}
                                                            </Typography>
                                                        </Box>

                                                    </Box>
                                                    <Box
                                                        sx={{ flex: '1' }}
                                                    >
                                                        <Box>
                                                            <Typography sx={{ color: '#808080', fontSize: '12px' }}>No Plat</Typography>
                                                            <Typography sx={{ color: '#404040', fontSize: '14px' }}>{selectedLocationData.police_number ? selectedLocationData.police_number : 'Tidak ada data nomor plat'}</Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{ mt: '16px' }}
                                                        >
                                                            <Typography sx={{ color: '#808080', fontSize: '12px' }}>{selectedLocationData.aktif === 'Ya' ? 'Masa Berlaku Saat Ini' : 'Masa Berlaku Terakhir'}</Typography>
                                                            <Typography sx={{ color: '#404040', fontSize: '14px' }}>{selectedLocationData.time_limit ?
                                                                <>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'flex-start',
                                                                            // alignItems: 'center',
                                                                            gap: '4px'
                                                                        }}
                                                                    >
                                                                        {moment(selectedLocationData.time_limit).format('Do MMMM YYYY')}
                                                                        {selectedLocationData.aktif === 'Ya' ?
                                                                            <Typography
                                                                                sx={{
                                                                                    fontWeight: 'bolder',
                                                                                    fontSize: '9px',
                                                                                    color: 'darkgreen'
                                                                                }}
                                                                            >
                                                                                Aktif
                                                                            </Typography>
                                                                            :
                                                                            <Typography
                                                                                sx={{
                                                                                    fontWeight: 'bolder',
                                                                                    fontSize: '9px',
                                                                                    color: 'crimson'
                                                                                }}
                                                                            >
                                                                                Tidak Aktif
                                                                            </Typography>
                                                                        }

                                                                    </Box>
                                                                </>
                                                                :
                                                                'Tidak ada data masa berlaku'}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box
                                                    sx={{ mt: '16px' }}
                                                >
                                                    <Typography sx={{ color: '#808080', fontSize: '12px' }}>Lokasi Member Terdaftar</Typography>
                                                    <Typography sx={{ color: '#404040', fontSize: '14px' }}>{selectedLocationData.locationname ? selectedLocationData.locationname : 'Tidak ada data lokasi'}</Typography>
                                                </Box>
                                            </Card>
                                            {dataPaket.length > 0 && selectedLocationData.extend === 'Y' ?
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: 'column',
                                                        // justifyContent: "center",
                                                        // alignItems: 'center',
                                                        mt: '20px'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            // backgroundColor: 'blue',
                                                            width: '100%',
                                                            // mb:'1px'
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: '12px',
                                                                fontWeight: '500',
                                                                color: '#404040',
                                                                mt: '10px',
                                                                mb: '10px',
                                                            }}
                                                        >
                                                            Pilih Paket Perpanjang
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            width: '100%',
                                                            // justifyContent: 'center',
                                                            // alignItems: 'center',
                                                            // backgroundColor:'orange',
                                                            mb: '20px'
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: '60%', // Sesuaikan lebar sesuai kebutuhan Anda
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: 'grid',
                                                                    gridTemplateColumns: 'repeat(2, 1fr)', // Tampilkan 2 kartu per baris (sesuaikan dengan jumlah yang Anda inginkan)
                                                                    gap: '12px',
                                                                    margin: '0 auto',
                                                                    // backgroundColor:'orange'
                                                                }}
                                                            >
                                                                {dataPaket.map((data, index) => (
                                                                    <Card
                                                                        key={index}
                                                                        sx={{
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            flexDirection: 'column',
                                                                            alignItems: "center",
                                                                            p: '8px 8px 8px 14px',
                                                                            width: '235px', // Sesuaikan lebar sesuai kebutuhan Anda
                                                                            borderRadius: '8px',
                                                                            // marginBottom: '16px', // Berikan margin bawah agar kartu terpisah
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}
                                                                        >
                                                                            <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>{data.name}</Typography>
                                                                            <FormControl>
                                                                                <RadioGroup
                                                                                    row
                                                                                    value={selectedPackage.id_membercategorylocations}
                                                                                    onChange={(event) => handleSelectPackage(event, data.id_membercategorylocations, data.price, data.duration)}
                                                                                >
                                                                                    <FormControlLabel

                                                                                        value={data.id_membercategorylocations}
                                                                                        control={<Radio size="small" />}
                                                                                    // required
                                                                                    />
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'flex-start',
                                                                                flexDirection: 'column',
                                                                                justifyContent: 'flex-start',
                                                                                width: '100%',
                                                                                gap: '5px'
                                                                            }}
                                                                        >
                                                                            <Typography sx={{ fontSize: '12px', fontWeight: '500px', color: '#808080' }}>Masa aktif {data.duration} hari.</Typography>
                                                                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: '#3836B4' }}>Rp. {data.price}</Typography>
                                                                        </Box>
                                                                    </Card>
                                                                ))}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                :
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: 'column',
                                                        justifyContent: "center",
                                                        alignItems: 'center',
                                                        mt: '30px',
                                                        mb: '20px',
                                                    }}
                                                >
                                                    <Typography sx={{ textAlign: 'justify', color: 'crimson' }}>Masa berlaku member masih lebih dari satu minggu, perpanjangan hanya dapat dilakukan jika masa berlaku member kurang dari satu minggu.</Typography>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    // mt: '15px',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '60%'
                                    }}
                                >
                                    <Button
                                        fullWidth
                                        type="submit"
                                        size="medium"
                                        variant='contained'
                                        sx={{
                                            padding: '10px 0px',
                                            backgroundColor: brandColor,
                                            textTransform: 'none',
                                            width: '100%',
                                            // maxWidth: '100px',
                                            '&:hover': {
                                                color: 'white', // Change text color to white on hover
                                                backgroundColor: brandColor,
                                            },
                                        }}
                                        disabled={loading ? true : false}
                                    >
                                        {loading ?
                                            <Icon icon='svg-spinners:bars-scale-fade' style={{ fontSize: '20px' }} />
                                            :
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal',
                                                }}
                                            >
                                                Proses Pembayaran
                                            </Typography>
                                        }
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Grid>
                <ToastContainer style={{ width: 'auto', minWidth: '300px' }} />
            </Grid>

    );
};

export default ComputerDetailMember;