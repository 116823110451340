import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import bg from "../../assets/background/Rectangle1.png"
import bgMobile from "../../assets/background/bg-mobile.png"
import Logo from "../../assets/icon/Logo.png"
import { useMediaQuery } from '@material-ui/core';

const BackgroundImageMobile = () => {
    const isMobileDevice = useMediaQuery('(min-width:620px)');
    const isTabletDevice = useMediaQuery('(min-width:768px )');
    const isPCDevice = useMediaQuery('(min-width:1025px)');

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    zIndex: 3,
                    marginTop: '20px',
                    marginLeft: '20px',
                    // backgroundColor:'green'
                }}
            >
                <img
                    src={Logo}
                    alt="parkways-logo"
                    style={{
                        width: '113.71px',
                        height: '24px',
                    }}
                />
                <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    marginTop='22px'
                    color='#ffffff'
                    gap='5px'
                // alignItems='center'
                // backgroundColor='orange'
                >
                    <Typography
                        sx={{
                            fontSize: isTabletDevice ? '24px' : '18px',
                            fontWeight: '500',
                            lineHeight: '21.78px',
                            // fontFamily:'Inter'
                        }}
                    >
                        Parkways Membership</Typography>
                    <Typography
                        sx={{
                            fontSize: isTabletDevice ? '38px' : '32px',
                            fontWeight: '600',
                            lineHeight: '38.73px',
                            // fontFamily:'Inter'
                        }}
                    >
                        Akses Parkir</Typography>
                    <Typography
                        sx={{
                            fontSize: isTabletDevice ? '24px' : '18px',
                            fontWeight: '500',
                            lineHeight: '21.78px',
                            // fontFamily:'Inter'
                        }}
                    >
                        Lebih mudah, murah dan anti ribet.</Typography>
                </Box>
            </Box>
            <img
                src={bgMobile}
                alt="parkways-background"
                width="100%"
                height="100%"
                style={{ position: 'absolute', zIndex: 1 }}
            />
        </>
    );
};

export default BackgroundImageMobile;
