import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import BackgroundImagePC from '../../components/BackgroundImg/BackgroundImgPC';
import Navbar from '../../components/navbar/Navbar';
import BackgroundImageMobile from '../../components/BackgroundImg/BackgroundImgMobile';
import { brandColor, orangeColor } from '../../components/Config/Color';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert';
import Computer from '../../components/responsive/extendmember/Computer';
import MobileDevice from '../../components/responsive/extendmember/MobileDevice';
import TabletDevice from '../../components/responsive/extendmember/TabletDevice';
import Loading from '../../components/Loading/Loading';



const ExtendMember = () => {
  const isMobileDevice = useMediaQuery('(min-width:620px)');
  const isTabletDevice = useMediaQuery('(min-width:768px )');
  const isPCDevice = useMediaQuery('(min-width:1025px)');

  const [loadingPage, setLoadingPage] = useState(false)

  useEffect(() => {
    setLoadingPage(true)
    setTimeout(() => {
      setLoadingPage(false)
    }, 3000);

  }, [])


  return (
    <>
      {loadingPage ? <Loading /> : isPCDevice ?
        <Computer />
        :
        isTabletDevice ? <TabletDevice />
          :
          <MobileDevice />
      }
    </>
  );
};

export default ExtendMember;
