import './App.css';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Router from './routes';
// import TopNavbar from './components/Navbar/Navbar';


function App() {
  return (
    <div className="maincontainer">
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </div>
  );
}

export default App;
